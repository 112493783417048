import { COLORS } from './common';

export default {
  label: 'Product',
  name: 'product',
  component: 'group',
  description: 'Add to cart, back in stock, badges, color swatches, reviews',
  fields: [
    {
      label: 'Add To Cart',
      name: 'addToCart',
      component: 'group',
      description: 'Add to cart, sold out, presale text, subtext',
      fields: [
        {
          label: 'Add To Cart Text',
          name: 'addToCartText',
          component: 'text',
        },
        {
          label: 'Sold Out Text',
          name: 'soldOutText',
          component: 'text',
        },
        {
          label: 'Preorder Text',
          name: 'preorderText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
          description: 'Text below the Add To Cart button',
        },
      ],
      defaultValue: {
        addToCartText: 'Add To Cart',
        soldOutText: 'Sold Out',
        preorderText: 'Preorder',
        subtext: '',
      },
    },
    {
      label: 'Back In Stock',
      name: 'backInStock',
      component: 'group',
      description: 'Enable notifications, notify me text, modal texts',
      fields: [
        {
          label: 'Enable Notifications',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Modal Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Modal Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Modal Heading - Success',
          name: 'headingSuccess',
          component: 'text',
        },
        {
          label: 'Modal Text - Success',
          name: 'subtextSuccess',
          component: 'text',
        },
        {
          label: 'Modal Submit Text',
          name: 'submitText',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: false,
        heading: 'Notify Me When Available',
        subtext: `Enter your email below and we'll notify you when this product is back in stock.`,
        headingSuccess: 'Success!',
        subtextSuccess:
          'You will be notified when this product is back in stock.',
        submitText: 'Notify Me When Available',
      },
    },
    {
      label: 'Badges',
      name: 'badges',
      component: 'group',
      description: 'Badge colors',
      fields: [
        {
          label: 'Badge Colors',
          name: 'badgeColors',
          component: 'group-list',
          description:
            'Note: product badges are set up via Shopify tags using the format "badge::Some Value"',
          itemProps: {
            label: '{{item.tag}}',
          },
          fields: [
            {
              label: 'Tag Value',
              name: 'tag',
              component: 'text',
              description:
                'Letter casing must be same as tag value in Shopify, e.g. "New", "Sale"',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: COLORS,
            },
          ],
          defaultItem: {
            bgColor: 'var(--black)',
            textColor: 'var(--white)',
          },
          defaultValue: [
            {
              tag: 'Draft',
              bgColor: 'var(--mediumGray)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Best Seller',
              bgColor: 'var(--black)',
              textColor: 'var(--white)',
            },
            {
              tag: 'New',
              bgColor: 'var(--secondary)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Sale',
              bgColor: 'var(--primary)',
              textColor: 'var(--white)',
            },
          ],
        },
      ],
    },
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      description: 'Color swatches',
      fields: [
        {
          label: 'Color Swatches',
          name: 'swatches',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          defaultItem: () => ({
            name: 'New Color',
          }),
          fields: [
            {
              label: 'Color Name',
              name: 'name',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              name: 'image',
              label: 'Image',
              component: 'image',
              description:
                'If provided, image will overlay the color.\nEnsure image is no more than 2KB in size',
            },
          ],
          defaultValue: [
            {
              name: 'Black',
              color: '#000000',
            },
            {
              name: 'White',
              color: '#FFFFFF',
            },
          ],
        },
      ],
    },
    {
      label: 'Reviews',
      name: 'reviews',
      component: 'group',
      description: 'Star rating',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabledStarRating: true,
      },
    },
    {
      label: 'Installments',
      name: 'installments',
      component: 'text',
      defaultValue: 'Pay in four installments with',
    },
    {
      label: 'Size Guide',
      name: 'sizeGuide',
      component: 'group',
      description: 'Size guide settings',
      fields: [
        {
          label: 'Size Guide Heading',
          name: 'heading',
          component: 'textarea',
          defaultValue: 'Size Guide',
        },
        {
          label: 'Size Guide Subtext',
          name: 'subtext',
          component: 'textarea',
          defaultValue: 'Find your perfect fit with our detailed size guide.',
        },
        {
          label: 'Use Tabs',
          name: 'useTabs',
          component: 'toggle',
          description: 'Use tabs to switch between different size guides',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Size Guides',
          name: 'sizeGuides',
          component: 'group-list',
          itemProps: {
            label: '{{item.productType}}',
          },
          defaultItem: {
            productType: 'bottoms',
            heading: 'Bottoms',
          },
          fields: [
            {
              label: 'Product Type',
              name: 'productType',
              component: 'text',
              description: 'If you want a single size guide to apply to all products with a given product type, enter the Product Type here. If you want the size guide to be product-specific for more flexibility, add a value here that matches a value that you input in the pack_size_guide Product Metafield.',
            },
            {
              label: 'Heading',
              name: 'heading',
              component: 'text',
              defaultValue: 'Bottoms',
            },
            {
              label: 'Size Table Rows',
              name: 'rows',
              component: 'group-list',
              description: 'Add rows to size guide',
              itemProps: {
                label: '{{item.values[0]}}',
              },
              fields: [
                {
                  label: 'Values',
                  name: 'values',
                  component: 'list',
                  description: 'Add values to row from left to right',
                  field: {
                    component: 'text',
                  },
                },
              ],
              defaultValue: [],
            },
          ],
          defaultValue: [
            {
              productType: 'tops',
              heading: 'TOPS',
              rows: [
                {
                  values: ['SIZE', 'XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL'],
                },
                {
                  values: [
                    'US Size',
                    '0-00',
                    '2-4',
                    '4-6',
                    '8-10',
                    '12-14',
                    '16-18',
                    '20-22',
                  ],
                },
                {
                  values: [
                    'Waist ({system})',
                    '23.5-24.5',
                    '25-26.5',
                    '27-28.5',
                    '29-30.5',
                    '31-32.5',
                    '33-34.5',
                    '35-36.5',
                  ],
                },
                {
                  values: [
                    'Hip ({system})',
                    '33-34',
                    '34.5-36',
                    '36.5-38',
                    '38.5-40',
                    '40.5-42.5',
                    '43-45',
                    '45.5-48',
                  ],
                },
              ],
            },
            {
              productType: 'bottoms',
              heading: 'Bottoms',
              rows: [
                {
                  values: ['SIZE', 'XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL'],
                },
                {
                  values: [
                    'US Size',
                    '0 - 00',
                    '2-4',
                    '4-6',
                    '8-10',
                    '12-14',
                    '16-18',
                    '20-22',
                  ],
                },
                {
                  values: [
                    'Waist ({system})',
                    '23.5-24.5',
                    '25-26.5',
                    '27-28.5',
                    '29-30.5',
                    '31-32.5',
                    '33-34.5',
                    '35-36.5',
                  ],
                },
                {
                  values: [
                    'Hip ({system})',
                    '33-34',
                    '34.5-36',
                    '36.5-38',
                    '38.5-40',
                    '40.5-42.5',
                    '43-45',
                    '45.5-48',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
